<template>
    <div>
        <app-layout>
	        <template v-slot:header>
                <Header :title="$t('payment_approvals')" :is-filter="false"></Header>
	        </template>
	        <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_approvals')" :is-filter="false"></HeaderMobile>
	        </template>
            <ValidationObserver ref="formValidate">
                <div class="row">
                    <div class="col-9">
                        <b-row>
                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="paymentPlanTypes" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('payment_plan_types')">
                                    <payment-plan-types-selectbox
                                        :validate-error="errors[0]"
                                        v-model="formData.type">
                                    </payment-plan-types-selectbox>
                                </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox
                                        :multiple="true"
                                        v-model="formData.faculty_code">
                                    </faculty-selectbox>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <b-form-group :label="$t('program_level')">
                                    <payment-program-level-selectbox
                                        v-model="formData.program_level">
                                    </payment-program-level-selectbox>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox
                                        :multiple="true"
                                        v-model="formData.program_code"
                                        :faculty_code="formData.faculty_code">
                                    </program-selectbox>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <b-form-group :label="$t('registration_type')">
                                    <parameter-selectbox
                                        :multiple="true"
                                        code="registration_types"
                                        v-model="formData.registration_type">
                                    </parameter-selectbox>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="registration_semester" rules="required_if:paymentPlanTypes,registration" v-slot="{valid, errors}">
                                <b-form-group :label="$t('registration_semester')">
                                    <semesters-selectbox
                                        v-model="formData.registration_semester_id">
                                    </semesters-selectbox>
                                </b-form-group>
                                </ValidationProvider>
                            </div>
                        </b-row>
                    </div>
                    <div class="col-3">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="formData.number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="create">{{ $t('start_process').toUpper() }}</b-button>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    import LinedTextarea from "@/components/elements/LinedTextarea";

    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import PaymentPlanService from "@/services/PaymentPlanService";
    import PaymentProgramLevelSelectbox from "@/components/interactive-fields/PaymentProgramLevelSelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import PaymentPlanTypesSelectbox from "@/components/interactive-fields/PaymentPlanTypesSelectbox.vue";

    export default {
        components: {
            PaymentPlanTypesSelectbox,
            FacultySelectbox,
            ParameterSelectbox,
            ProgramSelectbox,
            PaymentProgramLevelSelectbox,
            AppLayout,
            Header,
            HeaderMobile,
            LinedTextarea,

            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('payment_approvals')
            }
        },
        computed: {
            convertStudentNumbers() {
                if(this.formData.number){
                    return this.formData.number.split(/\n/);
                }
                return [];
            }
        },
        data() {
            return {
                formData: {}
            }
        },
        methods: {
            async create() {
                if(this.checkPermission('paymentplan_approvaljob')){
                    const isValid = await this.$refs.formValidate.validate();
                    if (isValid) {
                        let formData = {
                            ...this.formData
                        }

                        formData.number = this.convertStudentNumbers;
                        PaymentPlanService.paymentApprovalJob(formData)
                                          .then(response=>{
                                              if(!response.data.success){
                                                  this.toast(response.data);
                                                  return;
                                              }
                                              this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                                          });
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },
        }
    };
</script>

